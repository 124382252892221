/* eslint-disable jsx-a11y/alt-text */

import React, { Component } from 'react';
import Helmet from "react-helmet";
import { graphql, StaticQuery } from 'gatsby';

class Seo extends Component {
  render() {
    const social = this.props.data.allPrismicSocial.edges[0].node.data;

    let socialImage;

    if (this.props.image) {
      socialImage = this.props.image.url
    } else if (social.image) {
      socialImage = social.image.url
    }

    const siteMetadata = {
      title: this.props.title || social.title || `Alliance for Excellence in Jewish Leadershipp`,
      description: this.props.description || social.description || `This is the default description for Leading Edge.`,
      image: socialImage || null,
      siteName: social.siteName || `Leading Edge`,
      siteUrl: social.siteUrl || `https://www.leadingedge.org/`,
      siteLanguage: `en-US`,
      siteLocale: `en_us`,
      authorName: `Ronik`,
      author: `Ronik | https://www.ronikdesign.com`,
      twitterUsername: social.twitterUsername || `JewishLeaders`,
      unlisted: this.props.unlisted,
    }

    return (
      <Helmet
        htmlAttributes={{
          lang: siteMetadata.siteLanguage,
        }}
        bodyAttributes={{
          className: this.props.bodyClass
        }}
        title={siteMetadata.title}
        titleTemplate={`%s | ${siteMetadata.siteName}`}
        meta={[
          {
            name: `description`,
            content: siteMetadata.description,
          },
          {
            property: `og:title`,
            content: siteMetadata.title,
          },
          {
            property: `og:description`,
            content: siteMetadata.description,
          },
          {
            property: `og:image`,
            content: siteMetadata.image,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: siteMetadata.author,
          },
          {
            name: `twitter:title`,
            content: siteMetadata.title,
          },
          {
            name: `twitter:description`,
            content: siteMetadata.description,
          },
          {
            name: `viewport`,
            content: `width=device-width`,
          },
          {
            name: `robots`,
            content: siteMetadata.unlisted ? 'noindex' : 'all',
          }
        ]}
      >
        {/* Cookiebot */}
        <script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid="aedbf95a-fc49-4e08-8971-a9388839761f"
          data-blockingmode="auto"
          type="text/javascript"
        />

        {/* Accessibe */}
        <script>{`(function(){ var s = document.createElement('script'); var h = document.querySelector('head') || document.body; s.src = 'https://acsbapp.com/apps/app/dist/js/app.js'; s.async = true; s.onload = function(){ acsbJS.init({ statementLink : '', footerHtml : '', hideMobile : false, hideTrigger : false, disableBgProcess : false, language : 'en', position : 'right', leadColor : '#3f7e7c', triggerColor : '#3f7e7c', triggerRadius : '50%', triggerPositionX : 'right', triggerPositionY : 'bottom', triggerIcon : 'people', triggerSize : 'medium', triggerOffsetX : 20, triggerOffsetY : 20, mobile : { triggerSize : 'small', triggerPositionX : 'right', triggerPositionY : 'bottom', triggerOffsetX : 10, triggerOffsetY : 10, triggerRadius : '50%' } }); }; h.appendChild(s); })();`}</script>
      </Helmet>
    )
  }
}

const SeoComponent = (props) => (
  <StaticQuery
    query={graphql`
      {
        allPrismicSocial {
          edges {
            node {
              data {
                title
                description
                image {
                  url
                }
                site_name
                site_url
                twitter_username
              }
            }
          }
        }
      }
    `}
    render={data => <Seo data={data} {...props} />}
  />
);

export default SeoComponent;